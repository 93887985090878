@media only screen and (max-width: 735px) {
    .main-menu {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}

.ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.video-bg {
    position: absolute;
    height: 100vh !important;
    object-fit: cover !important;
    overflow: hidden;
    z-index: -100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(.15rem);
}

.App {
    background-size: cover !important;
    min-height: 100vh !important;
    background: url(../../assets/img/bg.jpg) no-repeat fixed center center;

}

.item-menu:hover {
    background: #FFFFFF80;
    border-radius: .5rem;

}